export const AUBProps = {
    heroBanner : {
        title: "Select A Project To Update",
        subtitle: "Powered by 36Boxes"
    },
    titleInput: {
        title: "Blog Title",
        name: "title",
        placeholder: "Enter the blog post title",
        value: ""
    },
}