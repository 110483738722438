export const CProps = {

    contactSection: {
        preContact: {
            title: "Get In Contact",
            description: ""
        },
        getInContact: {
            contactTitle: "Want to work with me?",
            contactSubtitle: "Get in contact and I will get back to you ASAP!"
        }
    }
}